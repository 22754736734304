import styled from 'styled-components'

export const Container = styled.div`
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.2);
  padding: 10px 20px;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  button {
    align-self: flex-end;
    margin-bottom: 20px;
  }

  @media (min-width: 765px) {
    width: 48%;
  }
`

export const Title = styled.h3`
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  margin: 20px 0;
`

export const Description = styled.div`
  margin: 20px 0;
  font-family: 'Roboto', sans-serif;
`

export const Image = styled.div<{ image: string }>`
  width: 100% !important;
  background: url(${props => props.image}) center center no-repeat;
  height: 200px !important;
  background-size: cover;
  border-radius: 8px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: ${props => props.theme.colors.gradient.bottomRight};
    opacity: 0.4;
    border-radius: 8px;
  }
`
