import React from 'react'
import Fade from 'react-reveal/Fade'

import Layout from 'layouts/index'
import ServiceCard from 'containers/ServiceCard'
import TitleBox from 'components/TitleBox'

import veterinarian from 'images/veterinarian.jpg'
import lab from 'images/laboratorio_1.jpg'
import ultrassom from 'images/ultrassom.jpg'
import internament from 'images/internamento_1.jpg'

import { PageContainer, FlexWrapper } from '../pageStyles/sobre-nos.styles'

import raioX from 'images/raiox_2.jpg'

const cardsInfo = [
  {
    title: 'Laboratório',
    image: lab,
    text:
      'A Clínica Veterinária Derosso possui parceria com o Laboratório Veterinário Próvita, especializado em patologia clínica e tem como responsável técnica a médica veterinária Drª Ana Laura D’Amico Fam.',
    slug: 'laboratorio',
  },
  {
    title: 'Internamento',
    image: internament,
    text:
      'Uma doença em nossos pets é sempre um momento delicado.Isso pode se agravar, pois é inevitável a permanecia deles em hospitalização.',
    slug: 'internamento',
  },
  {
    title: 'Diagnóstico por Imagem',
    image: ultrassom,
    text:
      'O Setor de Diagnóstico por Imagem da Clínica Veterinária Derosso conta com equipamentos modernos que produzem imagens de alta qualidade e conta profissionais especializados.',
    slug: 'diagnostico',
  },
  {
    title: 'Especialidades',
    image: veterinarian,
    text:
      'Muitas vezes, nossos companheiros precisam de uma atenção especial, para isso contamos com especialistas com conhecimentos únicos e avançados.',
    slug: 'especialidades',
  },
]

const Servicos = () => {
  return (
    <Layout>
      <>
        <TitleBox title='Nossos Serviços' bg={raioX} />
        <PageContainer>
          <Fade>
            <FlexWrapper>
              {cardsInfo.map((info, i) => {
                return (
                  <ServiceCard
                    key={i}
                    serviceName={info.title}
                    description={info.text}
                    image={info.image}
                    slug={info.slug}
                  />
                )
              })}
            </FlexWrapper>
          </Fade>
        </PageContainer>
      </>
    </Layout>
  )
}

export default Servicos
