import React from 'react'
import { navigate } from 'gatsby'

import Button from 'components/Button'

import { Container, Title, Description, Image } from './ServiceCards.styles'

interface Props {
  serviceName: string
  description: string
  image: string
  slug: string
}

const ServiceCard = ({ serviceName, description, image, slug }: Props) => {
  return (
    <Container>
      <Title>{serviceName}</Title>
      <Image image={image} />
      <Description>{description}</Description>
      <Button onClick={() => navigate(`/servicos/${slug}`)}>
        Mais informações
      </Button>
    </Container>
  )
}

export default ServiceCard
